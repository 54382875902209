import React, { useLayoutEffect, useRef,useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,FormControl,MenuItem,Menu,InputLabel } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,NO_OF_DAYS,MenuProps,MIN_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';
// import { Percent, PortableWifiOffSharp } from '@mui/icons-material';

const FutSpotOptLineChartContainer = (props) => {
  const seriesVWAPRef = useRef(null);
  const seriesVPOCRef = useRef(null);
  const candlestickSeriesRef = useRef(null);

  const seriesPutOIRef = useRef(null);
  const seriesCallOIRef = useRef(null);
  const seriesTotalOIRef = useRef(null);
  const seriesNetOIRef = useRef(null);

  const seriesPutVolRef = useRef(null);
  const seriesCallVolRef = useRef(null);

  const seriesPutDOIRef = useRef(null);
  const seriesCallDOIRef = useRef(null);
//   const seriesTotalDOIRef = useRef(null);
  const seriesNetDOIRef = useRef(null);

  const seriesPutDOIXRef = useRef(null);
  const seriesCallDOIXRef = useRef(null);
  // const seriesTotalDOIXRef = useRef(null);
  const seriesNetDOIXRef = useRef(null);
  const seriesTrpRef = useRef(null);

  // gamma lines
  const seriesGFHRef = useRef(null);
  const seriesGFLRef = useRef(null);
  const seriesGRHRef = useRef(null);
  const seriesGRLRef = useRef(null);
  const seriesGMRef = useRef(null);

  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isPro2User,setIsPro2User]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false)
  const[nDays,setnDays]=useState(props.nDays);
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");

  // const theme = useTheme();
  const navigate = useNavigate();

  // console.log("CONTAINER BEGINS")
  // console.log(props)

  useLayoutEffect(() => {
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        // wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: false,
        pinchZoomY: false,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        layout: root.verticalLayout
      })
    );

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true); 

    // add scrollbar to control
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    let rendrerDateTime = am5xy.AxisRendererX.new(root, {
      opposite: true,
    });
    rendrerDateTime.grid.template.setAll({
      location: 0,
      disabled: false,
    });
    rendrerDateTime.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTime.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxis = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM, HH:mm",
        "hour": "d MMM, HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, HH:mm",
      minHeight: 20,
      groupData: true,
      groupCount: 800,
      minZoomCount: 400,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        // { timeUnit: "minute", count: 2 },
        // { timeUnit: "minute", count: 5 },
        // { timeUnit: "minute", count: 15 },
        // { timeUnit: "minute", count: 30 },
        // { timeUnit: "hour", count: 1 },
      ],
      renderer: rendrerDateTime
    });

    //push the datetime axis in the chart
    let xAxis = chart.xAxes.push(
      dateTimeAxis
    );

    // let tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   autoTextColor: false,
    //   getLabelFillFromSprite: true,
    //   // labelText: "{name}: {valueY}"
    // });
    
    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.4,
    // });

    // % height of each panel
    let y1, y2, valueAxisLhs1, valueAxisRhs1, valueAxisLhs2, valueAxisRhs2;
    if(props.plotParams.get("plot") === "all_doix" && isPro2User) {
      y1 = 70;
      y2 = 30;

      valueAxisLhs1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y1),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );

      valueAxisLhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );

      valueAxisRhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // syncWithAxis: valueAxisLhs1,
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );

      valueAxisRhs1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // syncWithAxis: valueAxisLhs1,
          height: am5.percent(y1),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );
    }
    else {
      y1 = 100;

      valueAxisLhs1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y1),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );

      valueAxisRhs1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // syncWithAxis: valueAxisLhs1,
          height: am5.percent(y1),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );
    }
    
    let valueAxisFuturesRenderer = valueAxisRhs1.get("renderer");
    valueAxisFuturesRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 5,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      minPosition: 0.01,
      maxPosition: 0.99
    });

    let candlestickSeries;
    let seriesVWAP, seriesVPOC;
    if(props.plotParams.get("plot") === "fut") {
      seriesVWAP = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VWAP",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vwap",
          valueXField: "time",
          stroke: am5.color("#128E0B"),
          fill: am5.color("#128E0B"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesVWAP.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesVWAP.get("tooltip").label.set("text", "VWAP: {valueY}")

      seriesVPOC = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "VPOC",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vpoc",
          valueXField: "time",
          stroke: am5.color("#AB039D"),
          fill: am5.color("#AB039D"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesVPOC.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesVPOC.get("tooltip").label.set("text", "VPOC: {valueY}")
    }

    let seriesPutOI, seriesCallOI, seriesTotalOI, seriesNetOI;
    if(props.plotParams.get("plot") === "all_oi") {
      seriesPutOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PUT OI",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "put_oi",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesPutOI.get("tooltip").label.set("text", "PUT OI: {valueY}")

      seriesCallOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "CALL OI",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "call_oi",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesCallOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesCallOI.get("tooltip").label.set("text", "CALL OI: {valueY}")

      seriesTotalOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "TOTAL OI",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "total_oi",
          valueXField: "time",
          stroke: am5.color("#00FFFF"),
          fill: am5.color("#00FFFF"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesTotalOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesTotalOI.get("tooltip").label.set("text", "TOTAL OI: {valueY}")

      seriesNetOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "NET OI",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "net_oi",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          fill: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesNetOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesNetOI.get("tooltip").label.set("text", "NET OI: {valueY}")
    }

    let seriesPutVol, seriesCallVol;
    if(props.plotParams.get("plot") === "all_vol" || props.plotParams.get("plot") === "all_volx" || props.plotParams.get("plot") === "all_dvolx") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "undy_open",
          highValueYField: "undy_high",
          lowValueYField: "undy_low",
          valueYField: "undy_close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutVol = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: props.plotParams.get("plot") === "all_dvolx" ? "NTM PUT Volumes" : props.plotParams.get("plot") === "all_volx" ? "NTM PUT Volx" : "PUT Volumes",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: props.plotParams.get("plot") === "all_dvolx" ? "put_dvolx" : props.plotParams.get("plot") === "all_volx" ? "put_volx" : "put_vol",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutVol.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesPutVol.get("tooltip").label.set("text", "PE: {valueY}")

      seriesCallVol = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: props.plotParams.get("plot") === "all_dvolx" ? "NTM CALL Volumes" : props.plotParams.get("plot") === "all_volx" ? "NTM CALL Volx" : "CALL Volumes",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: props.plotParams.get("plot") === "all_dvolx" ? "call_dvolx" : props.plotParams.get("plot") === "all_volx" ? "call_volx" : "call_vol",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesCallVol.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesCallVol.get("tooltip").label.set("text", "CE: {valueY}")
    }

    let seriesPutDOI, seriesCallDOI, seriesNetDOI;  // seriesTotalDOI
    if(props.plotParams.get("plot") === "all_doi") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "undy_open",
          highValueYField: "undy_high",
          lowValueYField: "undy_low",
          valueYField: "undy_close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PUT OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "put_doi",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutDOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesPutDOI.get("tooltip").label.set("text", "PUT OI Change: {valueY}")

      seriesCallDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "CALL OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "call_doi",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesCallDOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesCallDOI.get("tooltip").label.set("text", "CALL OI Change: {valueY}")

      // seriesTotalDOI = chart.series.push(
      //   am5xy.LineSeries.new(root, {
      //     name: "TOTAL_DOI",
      //     xAxis: xAxis,
      //     yAxis: valueAxisRhs1,
      //     valueYField: "total_doi",
      //     valueXField: "time",
      //     stroke: am5.color("#00FFFF"),
      //     fill: am5.color("#00FFFF"),
      //     tooltip: am5.Tooltip.new(root, {})
      //   })
      // );

      // seriesTotalDOI.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      // seriesTotalDOI.get("tooltip").label.set("text", "TOTAL_DOI: {valueY}")

      seriesNetDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "NET OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "net_doi",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          fill: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesNetDOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesNetDOI.get("tooltip").label.set("text", "NET OI Change: {valueY}")
    }

    let seriesPutDOIX, seriesCallDOIX, seriesNetDOIX, seriesTotalDOIX, seriesTrp;
    let seriesGFH, seriesGFL;
    let seriesGRH, seriesGRL;
    let seriesGM;
    if(props.plotParams.get("plot") === "all_doix") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "undy_open",
          highValueYField: "undy_high",
          lowValueYField: "undy_low",
          valueYField: "undy_close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutDOIX = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "PE NTM OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "put_doix",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          zIndex: 5,
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesPutDOIX.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesPutDOIX.get("tooltip").label.set("text", "PE Trend: {valueY}")

      seriesCallDOIX = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "CE NTM OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "call_doix",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          zIndex: 5,
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesCallDOIX.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesCallDOIX.get("tooltip").label.set("text", "CE Trend: {valueY}")

      // seriesTotalDOIX = chart.series.push(
      //   am5xy.LineSeries.new(root, {
      //     name: "Avg NTM OI Change",
      //     xAxis: xAxis,
      //     yAxis: valueAxisLhs1,
      //     valueYField: "total_doix",
      //     valueXField: "time",
      //     stroke: am5.color("#00FFFF"),
      //     fill: am5.color("#00FFFF"),
      //     // tooltip: am5.Tooltip.new(root, {})
      //   })
      // );

      // seriesTotalDOIX.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      // seriesTotalDOIX.get("tooltip").label.set("text", "TOTAL_DOIX: {valueY}")

      if(isPro2User) {
        seriesNetDOIX = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Net NTM OI Change",
            xAxis: xAxis,
            syncWithAxis: valueAxisLhs2,
            yAxis: valueAxisLhs2,
            valueYField: "net_doix",
            valueYGrouped: "sum",
            valueXField: "time",
            clustered: false,
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesNetDOIX.columns.template.setAll({
          fillOpacity: 0.2,
          strokeWidth: 0,
          strokeOpacity: 0,
          width: am5.percent(100),
        })

        seriesNetDOIX.columns.template.adapters.add(
          "fill",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") >= 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )

        seriesNetDOIX.columns.template.adapters.add(
          "stroke",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") >= 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )
        // seriesNetDOIX.get("tooltip").label.set("text", "Fuel: {valueY}")

        seriesTrp = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Net NTM dOI",
            xAxis: xAxis,
            syncWithAxis: valueAxisRhs2,
            yAxis: valueAxisRhs2,
            valueYField: "net_doix_change",
            valueYGrouped: "extreme",
            valueXField: "time",
            clustered: false,
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesTrp.columns.template.setAll({
          fillOpacity: 0.6,
          strokeWidth: 0,
          strokeOpacity: 0,
          width: am5.percent(100),
        })

        seriesTrp.columns.template.adapters.add(
          "fill",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") > 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )

        seriesTrp.columns.template.adapters.add(
          "stroke",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") > 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )
        // seriesTrp.get("tooltip").label.set("text", "Push: {valueY}")
      }

      seriesGFH = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "mViz High",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "upper_mviz",
          valueYGrouped: "upper_mviz",
          valueXField: "time",
          stroke: am5.color("#DCFF00"),
          fill: am5.color("#DCFF00"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGFH.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesGFL = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "mViz Low",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "lower_mviz",
          valueYGrouped: "lower_mviz",
          valueXField: "time",
          stroke: am5.color("#DCFF00"),
          fill: am5.color("#DCFF00"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGFL.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesGRH = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "Gamma Wall High",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "gwall_high",
          valueYGrouped: "gwall_high",
          valueXField: "time",
          stroke: am5.color("#0ffaee"),
          fill: am5.color("#0ffaee"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGRH.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesGRL = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "Gamma Wall Low",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "gwall_low",
          valueYGrouped: "gwall_low",
          valueXField: "time",
          stroke: am5.color("#0ffaee"),
          fill: am5.color("#0ffaee"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGRL.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesGM = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "Gamma Zero",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "gzero",
          valueXField: "time",
          valueYGrouped: "gzero",
          stroke: am5.color("#4f92f7"),
          fill: am5.color("#4f92f7"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGM.strokes.template.setAll({
        strokeWidth: 2,
        strokeDasharray: [10, 6],
      });
    }
    
    if(props.plotParams.get("plot") === "fut") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "open",
          highValueYField: "high",
          lowValueYField: "low",
          valueYField: "close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );
    } else if(props.plotParams.get("plot") === "spt") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "undy_open",
          highValueYField: "undy_high",
          lowValueYField: "undy_low",
          valueYField: "undy_close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );
    }

    if(props.plotParams.get("plot") === "fut") {
        candlestickSeries.columns.template.states.create("riseFromOpen", {
            fill: am5.color(0x76b041),
            stroke: am5.color(0x76b041)
        });
        candlestickSeries.columns.template.states.create("dropFromOpen", {
            fill: am5.color(0xe4572e),
            stroke: am5.color(0xe4572e)
        });
    
        // candlestickSeries.get("tooltip").label.set("text", "Close: {valueY}");
        // candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")
    
        candlestickSeries.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "dd-MM-yyyy HH:mm",
            dateFields: ["time"]
        });
    }
    
    if(props.plotParams.get("plot") === "fut") {
      seriesVWAP.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesVPOC.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }

    if(props.plotParams.get("plot") === "all_oi") {
      seriesPutOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesCallOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesTotalOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesNetOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }

    if(props.plotParams.get("plot") === "all_vol" || props.plotParams.get("plot") === "all_volx" || props.plotParams.get("plot") === "all_dvolx") {
      candlestickSeries.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesPutVol.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesCallVol.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }

    if(props.plotParams.get("plot") === "all_doi") {
      candlestickSeries.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesPutDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesCallDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      // seriesTotalDOI.data.processor = am5.DataProcessor.new(root, {
      //   dateFormat: "dd-MM-yyyy HH:mm",
      //   dateFields: ["time"]
      // });
      seriesNetDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }

    if(props.plotParams.get("plot") === "all_doix") {
      candlestickSeries.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesPutDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesCallDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      // seriesTotalDOIX.data.processor = am5.DataProcessor.new(root, {
      //   dateFormat: "dd-MM-yyyy HH:mm",
      //   dateFields: ["time"]
      // });
      if(isPro2User){
        seriesNetDOIX.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
        seriesTrp.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
      }

      seriesGFH.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesGFL.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesGRH.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesGRL.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesGM.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }

    // candlestickSeries.get("tooltip").label.set("text", "[{column.fill}]Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);
    chart.rightAxesContainer.set("layout", root.verticalLayout);

    seriesVWAPRef.current = seriesVWAP;
    seriesVPOCRef.current = seriesVPOC;
    candlestickSeriesRef.current = candlestickSeries;
    
    seriesPutOIRef.current = seriesPutOI;
    seriesCallOIRef.current = seriesCallOI;
    seriesTotalOIRef.current = seriesTotalOI;
    seriesNetOIRef.current = seriesNetOI;

    seriesPutVolRef.current = seriesPutVol;
    seriesCallVolRef.current = seriesCallVol;

    seriesPutDOIRef.current = seriesPutDOI;
    seriesCallDOIRef.current = seriesCallDOI;
    // seriesTotalDOIRef.current = seriesTotalDOI;
    seriesNetDOIRef.current = seriesNetDOI;
    
    seriesPutDOIXRef.current = seriesPutDOIX;
    seriesCallDOIXRef.current = seriesCallDOIX;
    // seriesTotalDOIXRef.current = seriesTotalDOIX;
    seriesNetDOIXRef.current = seriesNetDOIX;
    seriesTrpRef.current = seriesTrp;
    seriesGFHRef.current = seriesGFH;
    seriesGFLRef.current = seriesGFL;
    seriesGRHRef.current = seriesGRH;
    seriesGRLRef.current = seriesGRL;
    seriesGMRef.current = seriesGM;

    //create chart legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));
    legend.data.setAll(chart.series.values);

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      y: am5.percent(100),
      centerY: am5.percent(100),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7
    });

    // Add cursor for date time x axis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    //add tooltip for date time x axis
    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    return () => {
      root.dispose();
    };

  }, []);

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if (props.chartData != undefined && props.chartData.length > 0) {
      if (props.isIncrementalData) {
        console.log("incremental data updates", props.chartData);
        if(props.plotParams.get("plot") === "fut") {
          candlestickSeriesRef.current.data.pushAll(props.chartData);
          seriesVWAPRef.current.data.pushAll(props.chartData);
          seriesVPOCRef.current.data.pushAll(props.chartData);
        }
        
        if(props.plotParams.get("plot") === "all_oi") {
          seriesPutOIRef.current.data.pushAll(props.chartData);
          seriesCallOIRef.current.data.pushAll(props.chartData);
          seriesTotalOIRef.current.data.pushAll(props.chartData);
          seriesNetOIRef.current.data.pushAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_vol" || props.plotParams.get("plot") === "all_volx" || props.plotParams.get("plot") === "all_dvolx") {
          candlestickSeriesRef.current.data.pushAll(props.chartData);
          seriesPutVolRef.current.data.pushAll(props.chartData);
          seriesCallVolRef.current.data.pushAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_doi") {
          candlestickSeriesRef.current.data.pushAll(props.chartData);
          seriesPutDOIRef.current.data.pushAll(props.chartData);
          seriesCallDOIRef.current.data.pushAll(props.chartData);
          // seriesTotalDOIRef.current.data.pushAll(props.chartData);
          seriesNetDOIRef.current.data.pushAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_doix") {
          candlestickSeriesRef.current.data.pushAll(props.chartData);
          seriesPutDOIXRef.current.data.pushAll(props.chartData);
          seriesCallDOIXRef.current.data.pushAll(props.chartData);
          // seriesTotalDOIXRef.current.data.pushAll(props.chartData);
          if(isPro2User){
            seriesNetDOIXRef.current.data.pushAll(props.chartData);
            seriesTrpRef.current.data.pushAll(props.chartData);
          }
          seriesGFHRef.current.data.pushAll(props.chartData);
          seriesGFLRef.current.data.pushAll(props.chartData);
          seriesGRHRef.current.data.pushAll(props.chartData);
          seriesGRLRef.current.data.pushAll(props.chartData);
          seriesGMRef.current.data.pushAll(props.chartData);
        }
        
      } else {
        console.log("initial chart load");

        if(props.plotParams.get("plot") === "fut") {
          candlestickSeriesRef.current.data.setAll(props.chartData);
          seriesVWAPRef.current.data.setAll(props.chartData);
          seriesVPOCRef.current.data.setAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_oi") {
          seriesPutOIRef.current.data.setAll(props.chartData);
          seriesCallOIRef.current.data.setAll(props.chartData);
          seriesTotalOIRef.current.data.setAll(props.chartData);
          seriesNetOIRef.current.data.setAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_vol" || props.plotParams.get("plot") === "all_volx" || props.plotParams.get("plot") === "all_dvolx") {
          candlestickSeriesRef.current.data.setAll(props.chartData);
          seriesPutVolRef.current.data.setAll(props.chartData);
          seriesCallVolRef.current.data.setAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_doi") {
          candlestickSeriesRef.current.data.setAll(props.chartData);
          seriesPutDOIRef.current.data.setAll(props.chartData);
          seriesCallDOIRef.current.data.setAll(props.chartData);
          // seriesTotalDOIRef.current.data.setAll(props.chartData);
          seriesNetDOIRef.current.data.setAll(props.chartData);
        }

        if(props.plotParams.get("plot") === "all_doix") {
          candlestickSeriesRef.current.data.setAll(props.chartData);
          seriesPutDOIXRef.current.data.setAll(props.chartData);
          seriesCallDOIXRef.current.data.setAll(props.chartData);
          // seriesTotalDOIXRef.current.data.setAll(props.chartData);
          if(isPro2User){
            seriesNetDOIXRef.current.data.setAll(props.chartData);
            seriesTrpRef.current.data.setAll(props.chartData);
          }
          seriesGFHRef.current.data.setAll(props.chartData);
          seriesGFLRef.current.data.setAll(props.chartData);
          seriesGRHRef.current.data.setAll(props.chartData);
          seriesGRLRef.current.data.setAll(props.chartData);
          seriesGMRef.current.data.setAll(props.chartData);
        }
      }
    }

    seriesNetDOIXRef.current?.hide();
    // seriesGFHRef.current?.hide();
    // seriesGFLRef.current?.hide();

  }, [props.plotParams, props.chartData, props.isIncrementalData]);

  function toggleFullScreen() {
    let chartdiv = document.querySelector(`#${props.id}`)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const handlenDaysChange=(event)=>{
    setnDays(event.target.value);
    props.changenDays(event.target.value);
  }
  
  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  } 

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.plotParams.get("displayInstr")} All Options {props.plotParams.get("plot") == "all_doi" ? "OI Change" : props.plotParams.get("plot") == "all_doix" ? "NTM OI Change" : ""} Chart</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
        {!isBasicUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
            {/* <Typography sx={{mt:1,mr:0,fontSize:16,width:"100px"}}>No. of days</Typography> */}
        
            <FormControl size="small" sx={{ width:"90px",minWidth:'90px',ml:0,mt:0 }}>
              <InputLabel id="demo-simple-select-label">No. of days</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nDays}
                MenuProps={MenuProps}
                sx={{height:"40px"}}
                label="No. of days"
                onChange={(event)=>handlenDaysChange(event)}
                >
                {NO_OF_DAYS.map((item,index) => (
                <MenuItem value={item.value}>{item.key}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <DatePicker onChange={onDateChange} 
              allowClear={false}
              disabledDate={disabledDate}
              placeholder="Select Start Date"
              value={startDate}
              format={DateFormat}
              style={{border: "1px solid #484848",background:"#111111",
              borderRadius: "4px",width:200,marginLeft:40,marginTop:"0px"}}
              /> 
          </Box>
          </>
          :<></>}

          <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
          <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "800px" }} />
        </div>
      </div>
    </>
  )
}

export default FutSpotOptLineChartContainer
