import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"

import { Alert, CircularProgress, Divider, Snackbar } from "@mui/material"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAxiosWithAuth } from "../../common/api/hooks/useAxiosWithAuth"
import { URL } from "../../common/api/urls"
import useAuth from "../../common/hooks/useAuth"
import {
  BOOKMARK_CATEGORY_AUTOSAVE,
  CONSTANTS,
  MP_CHART_RELOAD_TIME,
  SNACKBAR_AUTO_HIDE_DURATION,
  TEXT_MSGS,
  TIME_FRAME_VALUES,
  USER_RESOURCES_LIST,
  USER_ROLE_FIELDS,
} from "../../common/utility/constant"
import { breakDSTR, useQuery } from "../../common/utility/helperFunc"
import {
  CHART_TYPE,
  MAX_PROFILE_TO_LOAD,
  VA_TYPE,
  VP_TYPE,
} from "../../common/utility/mp_chart_constants"
import { UserSettingsContext } from "../../setup/routes-manager/RequireAuth"
import BarChart from "./bar_chart"
import LineChartFooter from "./components/LineChartFooter"
import ConfirmActionDialog from "./components/confirm_dialog"
import MPChart from "./components/mpchart"
import MPChartFooter from "./components/mpchart_footer"
import MPChartHeaderSettings from "./components/mpchart_header"
import MPChartRightPanel from "./components/mpchart_right_panel"
import PRICE_TABLE from "./components/price_table"
import LineChart from "./line_chart"
import OrderflowContainer from "./orderflow_container"
import StockChartHV from "./stock_chart_hv"
import ChartContainer from "./chart_container";
import LineChartContainer from "./LineChartContainer"
import BarChartSettings from "./components/barchart_settings"
import LineChartContainerNew from "./LineChartContainerNew"

// const DEFAULT_PROFILE_WIDTH=120;
// const DEFAULT_PROFILE_WIDTH_WEEKLY=200;
// const DEFAULT_PROFILE_WIDTH_MONTHLY=200;
// const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const DEFAULT_PROFILE_WIDTH = 60
const DEFAULT_PROFILE_WIDTH_WEEKLY = 100
const DEFAULT_PROFILE_WIDTH_MONTHLY = 120
const DEFAULT_PROFILE_WIDTH_YEARLY = 400
const PROFILE_LOAD_FACTOR = 1.2

const BarChartContainer = (props) => {
  // console.log("props==========================>",props);
  const { auth } = useAuth();
  const isEliteUser=auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED)
  const location = useLocation()
  const [chartType, setChartType] = useState(
    props.chartType ? props.chartType : CHART_TYPE.BAR_CHART
  )
  const { userSettings, setUserSettings } = useContext(UserSettingsContext)
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  // const from = location.state?.from?.pathname || "/login";
  const from = "/login"
  const symbol = useQuery("symbol")
  console.log("props.key 1=", props.key, props.val)
  const [state, setState] = useState({
    isLoading: true,
    liveData: undefined,
    chartData: undefined,
    krlData: undefined,
    instrument: props.symbol
      ? props.symbol
      : symbol && props.val == 0
      ? symbol
      : userSettings.data.default_instrument,
    // instrument:symbol&&props.val==0?symbol:userSettings.data.default_instrument,
    isIncrementalData: false,
    lastLoadTime: -1,
    isInstrumentLive: false,
    reloadInerval: auth.role.find((el) => el == USER_ROLE_FIELDS.FAST_DATA)
      ? MP_CHART_RELOAD_TIME.FAST_DATA
      : MP_CHART_RELOAD_TIME.SLOW_DATA,
    pollingInterval: MP_CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval: -1,
    selectedTimeFrame: "daily",
    isPollingAllowed: false,
    isTradingSessionActive: false,
    isJsonLoaded: false,
    isFirtLoadComplete: false,
    apiData: undefined,
    bookmarkData: undefined,
    isStatusCheckAllowed: false,
    statusCheckPollingInterval: MP_CHART_RELOAD_TIME.POLLING_TIME,
    isLivePollingAllowed: false,
    krlPollingTime: MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
    krlPollingAllowed: false,
    tradeDates: undefined,
    dstrData: undefined,
    chartRendered:false,
  })
  const [
    cancelUiData,
    responseDataUiData,
    errorUiData,
    loadedUiData,
    resetUiData,
    executeAPIUiData,
  ] = useAxiosWithAuth()
  const [selectedInstrumentData, setSelectedInstrumentData] = React.useState()
  const [selectedInstrument, setSelectedInstrument] = React.useState("")
  const [instrumentsData, setInstrumentsData] = useState()
const [instrumentsLoaded, setInstrumentsLoaded] = useState(false);


  const [footerLoaded, setFooterLoaded] = useState(false)
  const [npocAllowed, setNPOCAllowed] = useState(
    auth.role.find((el) => el == USER_ROLE_FIELDS.FAST_DATA) ? true : false
  )

  const [msgState, setMsgState] = useState({
    open: false,
    msg: "",
    severity: "info",
  })

  const { open, msg, severity } = msgState

  const {
    isLoading,
    chartData,
    liveData,
    krlData,
    isIncrementalData,
    isInstrumentLive,
    lastLoadTime,
    reloadInerval,
    pollingInterval,
    instrument,
    refreshInterval,
    selectedTimeFrame,
    isPollingAllowed,
    isTradingSessionActive,
    isJsonLoaded,
    isFirtLoadComplete,
    apiData,
    bookmarkData,
    isStatusCheckAllowed,
    statusCheckPollingInterval,
    isLivePollingAllowed,
    krlPollingTime,
    krlPollingAllowed,
    tradeDates,
    dstrData,
    chartRendered,
  } = state
  const stateRef = useRef(state)

  const [cancel, responseData, error, loaded, reset, executeAPI] =
    useAxiosWithAuth()
  const [
    cancelLive,
    responseDataLive,
    errorLive,
    loadedLive,
    resetLive,
    executeLive,
  ] = useAxiosWithAuth()
  const [
    cancelSaved,
    responseDataSaved,
    errorSaved,
    loadedSaved,
    resetSaved,
    executeAPISaved,
  ] = useAxiosWithAuth()
  const [
    cancelDelete,
    responseDataDelete,
    errorDelete,
    loadedDelete,
    resetDelete,
    executeAPIDelete,
  ] = useAxiosWithAuth()
  const [
    cancelStatus,
    responseDataStatus,
    errorStatus,
    loadedStatus,
    resetStatus,
    executeAPIStatus,
  ] = useAxiosWithAuth()
  const [
    cancelKRL,
    responseDataKRL,
    errorKRL,
    loadedKRL,
    resetKRL,
    executeAPIKRL,
  ] = useAxiosWithAuth()
  const [
    cancelKRLPoll,
    responseDataKRLPoll,
    errorKRLPoll,
    loadedKRLPoll,
    resetKRLPoll,
    executeAPIKRLPoll,
  ] = useAxiosWithAuth()

  const selectedInstrumentRef = useRef(selectedInstrument)
  const [selectedTPO, setSelectedTPO] = useState(2)
  const [selectedInstrumentTPOData, setSelectedInstrumentTPOData] = useState()
  const [globalVolumeVisible, setGlobalVolumeVisible] = useState(
    userSettings.data.show_global_vp
  )
  const [volumeNumberVisible, setVolumeNumberVisible] = useState(
    userSettings.data.show_global_vp_numbers
  )
  const [onlyVolumeProfile, setOnlyVolumeProfile] = useState(false)
  const [tpoOnVolume, setTpoOnVolume] = useState(false)
  const [perProfilelVolumeVisible, setPerProfileVolumeVisible] = useState(false)
  const [perProfileVolumeNumberVisible, setProfileVolumeNumberVisible] =
    useState(false)
  const navigate = useNavigate()
  const [autoCenter, setAutocenter] = useState(false)
  // const [viewState, setViewState] = React.useState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  const [viewState, setViewState] = React.useState({
    tpo_only:
      userSettings.data.vp_type == VP_TYPE.NONE ||
      userSettings.data.vp_type == VP_TYPE.BACKGROUND,
    vol_tpo_side: userSettings.data.vp_type == VP_TYPE.ASIDE,
    only_vol: userSettings.data.vp_type == VP_TYPE.ALONE,
    tpo_on_vol: userSettings.data.vp_type == VP_TYPE.BACKGROUND,
  })

  const [tpoBasedVA, setTPOBasedVA] = useState(userSettings.data.va_type)
  const [toggleReset, setToggleReset] = useState(false)
  const [toggleUnselect, setToggleUnselect] = useState(false)
  const [rightPanelConatinerState, setRightPanelConatinerState] = useState({
    show: false,
    metadata: undefined,
    histoticalData: undefined,
    selectedIndexes: [],
    isLiveSession: false,
  })
  const [toggleSave, setToggleSave] = useState(false)
  const [toggleSaveBookmark, setToggleBookmark] = useState(false)
  const [jsonData, setJsonData] = useState()
  const [vpType, setVPType] = React.useState(userSettings.data.vp_type)
  const [stateData, setStateData] = useState(props.stateData)
  const [openAutosaveDeleteDialog, setOpenAutosaveDeleteDialog] =
    useState(false)
  const [reloadInstrument, setReloadInstrument] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [toggleMPLoading, setToggleMPLoading] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [tableData, setTableData] = useState(false)
  const [levelData, setLevelData] = useState([-1, -1]);
  const [showSettings,setShowSettings]=useState(false);

  const openStockChart = () => {
    navigate(
      `/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`,
      { replace: false }
    )
  }

  useEffect(() => {
    // executeAPIKRL(URL.GET_KRL_LIST,"POST",{"symbol":"NIFTY"})
    console.log("props MP container=", props)
    if (props.setChartType) props.setChartType(chartType)
  }, [])

  const openMPChart = () => {
    setShowTable(false)
    if (chartType != CHART_TYPE.MARKET_PROFILE) {
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel()
      // console.log("state=",state);
      setPollingAllowedVal(true)
      setChartType(CHART_TYPE.MARKET_PROFILE)
      if (props.setChartType != undefined)
        props.setChartType(CHART_TYPE.MARKET_PROFILE)

      // setState((prevState) => ({
      //   ...prevState,
      //   isLoading:false}));

      // setSelectedInstrument(selectedInstrument);
    }
    // navigate(`/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });
  }

  const openLineChart = () => {
    // if (chartType != CHART_TYPE.LINE_CHART) {
      console.log("Future test selected instrument",stateData)
      console.log("live panel resetRightPanel===2")
      props.resetRightPanel()
      setPollingAllowedVal(false);
      setStateData(undefined);
      setChartType(CHART_TYPE.LINE_CHART)
      if (props.setChartType != undefined)
        props.setChartType(CHART_TYPE.LINE_CHART)
    // }
    // navigate(`/line-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });
  }

  const openOFChart = () => {
    setShowTable(false)
    if (chartType != CHART_TYPE.OF_CHART) {
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel()
      // console.log("state=",state);
      setPollingAllowedVal(false)
      setChartType(CHART_TYPE.OF_CHART)
      if (props.setChartType != undefined)
        props.setChartType(CHART_TYPE.OF_CHART)
    }
  }

  useEffect(() => {
    console.log(
      "Chart container Props changes, proUser",
      props.chartStateData,
      props.proUser
    )
  }, [props.chartStateData])


  

  //execute the data fetch on the component mount
  useEffect(() => {
    // if(selectedInstrument!=undefined && isLoading){
   
   
    setIsDirty(true)
    setJsonData(undefined)
    setState((prevState) => ({
      ...prevState,
      isJsonLoaded: true,
      isFirtLoadComplete: true,
    }))
  }, [])

  useEffect(() => {
   
    if (selectedInstrument != undefined) {
      // setJsonData(undefined);
      setState((prevState) => ({
        ...prevState,
        // apiData:undefined,
        bookmarkData: undefined,
        // isJsonLoaded:false,
      }))
      setFooterLoaded(true)
    
      setIsDirty(true)
    }
  }, [selectedInstrument, selectedTimeFrame, toggleMPLoading])

  useState(() => {
    console.log("charttype,state", chartType, state)
  }, [state])
 

 

  const setPollingAllowedVal = (val) => {
    setState((prevState) => ({
      ...prevState,
      isPollingAllowed: val,
    }))
  }

 


  const handleVPType = (val) => {
    setVPType(val)
  }

  const handleFooterLoaded = () => {
    setFooterLoaded(true)
  }

  const handleChartChange = (data) => {
    console.log("handleChartChange", data)
    setStateData(data)
    setChartType(data.data.chart_type)
  }

  

  useEffect(() => {
    selectedInstrumentRef.current = selectedInstrument
    console.log(
      "useeffect selectedinstrument",
      selectedInstrument,
      selectedInstrumentRef.current
    )
  }, [selectedInstrument])


 

  const showPriceTable = () => {
    console.log("showTable=", showTable)
    setShowTable(true)
  }

  const showSubscribeModal = (isCustom, msg) => {
    props.openSubscribeModal(isCustom, msg)
  }


  useEffect(() => {
    console.log("Boundary levelData useeffect chart container=", levelData)
    // loadDataLevel();
  }, [levelData])

  const openSunscriptionModal = (isCustom, msg) => {
    props.openSubscribePropModal(isCustom, msg)
  }

  useEffect(() => {
    let data = {
      symbol: "NIFTY",
      instruments: 1,
      tpoList: 1,
      additional_instruments: [symbol],
      dateList: 1,
    }
    executeAPIUiData(URL.MPCHART_UIDATA, "POST", data)
  }, [])

  /**
   * API response handler
   */
  useEffect(() => {
    if (loadedUiData) {
      if (responseDataUiData != null) {
        responseDataUiData.instruments = responseDataUiData.instruments.filter(
          (e) => e.line_chart === true
        )
        let index = 0
        if (symbol != undefined && symbol != "") {
          index = responseDataUiData.instruments.findIndex(
            (item) => item.instr === symbol
          )
          if (index == -1) index = 0
        } else if (
          userSettings != undefined &&
          userSettings.data != undefined
        ) {
          index = responseDataUiData.instruments.findIndex(
            (item) => item.name === userSettings.data.default_instrument
          )
          if (index == -1) index = 0
        }
        // setOptions(responseDataUiData.instruments)
        setInstrumentsData(responseDataUiData.instruments) // all data
      if(props.stateData==undefined&& props.val==0){
        console.log("searchparams test line chart container=",props.stateData)
      setSearchParams({"symbol":responseDataUiData.instruments[index].instr})
      }

        setSelectedInstrument(responseDataUiData.instruments[index].instr) // only name of selected data
        setSelectedInstrumentData(responseDataUiData.instruments[index]) // current selected all data
      setInstrumentsLoaded(true);
    //   setState((prevState) => ({
    // ...prevState,
    // isLoading:false,
    // }));

        // setTradeDates(responseData.tradeDates);
        // props.instrumentChange(
        //   responseData.instruments[index],
        //   responseData.tpo_groups[responseData.instruments[index].tpo_group]
        //     ? responseData.tpo_groups[responseData.instruments[index].tpo_group]
        //     : responseData.tpo_groups["DEFAULT"],
        //   tempTPO,
        //   responseData.tradeDates
        // );
        // setInstrumentLoaded(true);
      } else if (error !== null) {
        console.log("Error data=", error)
        reset()
      }
    }
  }, [loadedUiData, responseDataUiData])

  const instrumentChange = (data) => {
    setSelectedInstrument(data.instr)
    setSelectedInstrumentData(data)
  }

  const setChartRenderedState=(val)=>{
    setState((prevState)=>({
      ...prevState,
      chartRendered:val
    }))
    if(props.setChartLoaded){
      // console.log("HelloTest val 1=",val)
      const timeout = setTimeout(() => {
        props.setChartLoaded(val);
    }, 1000);
      
    }
   }

 useEffect(() => {
  console.log("useEffect is called for props.stateData Line chart container",props.stateData)
 
  // if(instrumentsData && props.stateData){
  //   setInstrumentsLoaded(false);
  //   let index = responseDataUiData.instruments.findIndex((item) => item.instr === props.stateData.symbol)
  //   console.log("useEffect is called for props.stateData Line chart container 1",props.stateData,index)
  //   if(index!=-1){
  //     setSelectedInstrument(responseDataUiData.instruments[index].instr) // only name of selected data
  //     setSelectedInstrumentData(responseDataUiData.instruments[index]) // current selected all data
  //     }
  // }
  setStateData(props.stateData);
  // setInstrumentsLoaded(true);

}, [props.stateData])

const openSettings=()=>{
  setShowSettings(true);
 }

 const cancelSettings=()=>{
  setShowSettings(false);
 }

 const saveSettings=(settings)=>{
  setShowSettings(false);
  // console.log(" Settings save settings in chart container",settings);
  setUserSettings(settings);
 }

  return (
    <>
      {chartType == CHART_TYPE.MARKET_PROFILE ? (
      
        <ChartContainer
        id={props.id}
        val={props.val}
        boundClass={props.boundClass}
        chartScreenType={props.chartScreenType}
        setChartType={props.setChartType}
        chartStateData={props.chartStateData}
        //symbol={selectedInstrument}
        resetRightPanel={props.resetRightPanel}
        setRightPanel={props.setRightPanel}
        openSubscribeModal={props.openSubscribePropModal}
        basicUser={props.basicUser}
        proUser={props.proUser}
        setChartLoaded={props.setChartLoaded}
      ></ChartContainer>
        )
       : (
        <>
          {chartType == CHART_TYPE.OF_CHART ? (
            <OrderflowContainer
              id={props.id}
              val={props.val}
              boundClass={props.boundClass}
              chartScreenType={props.chartScreenType}
              setChartType={props.setChartType}
              chartStateData={props.chartStateData}
              //symbol={selectedInstrument}
              resetRightPanel={props.resetRightPanel}
              setRightPanel={props.setRightPanel}
              openSubscribeModal={props.openSubscribePropModal}
              basicUser={props.basicUser}
              proUser={props.proUser}
              setChartLoaded={props.setChartLoaded}
            ></OrderflowContainer>
          ) : (
            <></>
          )}
     
           {chartType==CHART_TYPE.LINE_CHART?
    // <LineChart id={props.id} val={props.val} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></LineChart>
    <>
    {isEliteUser?
    <LineChartContainerNew
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
  />:
    <LineChartContainer
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
  ></LineChartContainer>
    }
  </>
    :
    <></>}
          {chartType == CHART_TYPE.HV_CHART ? (
            <StockChartHV
              id={props.id}
              stateData={stateData}
              symbol={selectedInstrument}
              nDays={userSettings.data.default_lc_ndays}
            ></StockChartHV>
          ) : (
            <></>
          )}
          {chartType == CHART_TYPE.BAR_CHART ? (
            <BarChart
              id={props.id}
              stateData={stateData}
              symbol={selectedInstrument}
              nDays={userSettings.data.default_lc_ndays}
            ></BarChart>
          ) : (
            <></>
          )}

          {chartType != CHART_TYPE.OF_CHART && chartType != CHART_TYPE.MARKET_PROFILE && chartType != CHART_TYPE.LINE_CHART && selectedInstrumentData ? (
            <LineChartFooter
              chartType={chartType}
              chartStateData={props.chartStateData}
              userSettings={userSettings}
              selectedInstrumentData={selectedInstrumentData}
              openStockChart={openStockChart}
              selectedInstrument={selectedInstrument}
              openLineChart={openLineChart}
              basicUser={props.basicUser}
              handleVPType={handleVPType}
              handleFooterLoaded={handleFooterLoaded}
              handleChartChange={handleChartChange}
              auth={auth}
              showPriceTable={showPriceTable}
              showTable={showTable}
              openSubscribeModal={showSubscribeModal}
              openOFChart={openOFChart}
              openMPChart={openMPChart}
              openSettings={openSettings}
              isEliteUser={isEliteUser}
            />
          ) : (
            <></>
          )}
        </>
      )}
       {showSettings?
     <BarChartSettings open={true} cancelHandler={cancelSettings} saveHandler={saveSettings} ></BarChartSettings>    :
     <></>
    }
    </>
  )
}

export default BarChartContainer